import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, Loader } from 'smart-react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { buildNotification, GenerateNotification } from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';

/**
 * Component for managing user settings.
 * @returns {JSX.Element} Settings component.
 */
const Settings = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    setIsLoader(true);

    // Load saved API key from localStorage
    const savedApiKey = localStorage.getItem('openai_api_key');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
    setIsLoader(false);
  }, []);

  /**
   * Handles saving of API key.
   */
  const handleSave = () => {
    if (apiKey.trim().length===0){
      GenerateNotification(
        buildNotification({
          title: 'Please Enter Key!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      return;
    }
    setIsLoader(true);
    // Save API key to localStorage
    localStorage.setItem('openai_api_key', apiKey);
    setIsLoader(false);
    GenerateNotification(
      buildNotification({
        title: 'API Key Saved Successfully!',
        description: '',
        style: 'success',
      }),
      NOTIFICATION_TYPES.APP,
      EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {isLoader && (
        <div className='loader-wrapper'>
          <Loader />
        </div>
      )}
      <div className='multi-image-upload k-pr-2 k-pl-2 k-mt-3'>
        <h5 className='k-p-4 k-m-0 k-font-weight-bold'>Settings</h5>
        <div
          className='dashboard-container k-p-5'
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <label htmlFor='openai_api_key' style={{ marginRight: '10px' }}>
            OpenAI API Key:
          </label>
          <Input
            id='openai_api_key'
            name='openai_api_key'
            value={apiKey}
            onChange={(event) => setApiKey(event.target.value)}
          />
          <Button
            className='ai-button-primary k-mt-2'
            onClick={handleSave}
            style={{ alignSelf: 'flex-end' }}
          >
            Save
          </Button>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Settings;
