/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
import React from 'react';

export const DataColumns = ({ handleEdit, handleDelete, publishUrl }) => {
  const dataColumns = [
    {
      field: 'name',
      title: 'Name',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 150,
    },
    {
      field: 'instructions',
      title: 'Instructions',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'type',
      title: 'Type',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'action',
      title: 'Actions',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      cell: (props) => (
        <td>
          <button
            className='k-button-icon k-font-icon k-i-pencil k-border-0 k-cursor-pointer k-m-2 k-bg-none'
            onClick={() => handleEdit(props.dataItem)}
          />
          <button
            className='delete-button k-button-icon k-font-icon k-i-trash k-cursor-pointer k-m-2'
            onClick={() => handleDelete(props.dataItem)}
          />
        </td>
      ),
    },
    {
      field: 'Generate_Publish_URL',
      title: 'Generate Publish URL',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      cell: (props) => (
        <td>
          <button
            className='k-button-icon k-font-icon k-i-export k-border-0 k-cursor-pointer k-m-2 k-bg-none'
            onClick={() => publishUrl(props.dataItem)}
          />
        </td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
