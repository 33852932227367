import React, { useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useDropzone } from 'react-dropzone';
import { Loader } from 'smart-react';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { uploadFile, listFiles, deleteFile } from '../Services/FileService';
import { buildNotification, GenerateNotification } from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';

/**
 * File management component.
 * @returns {JSX.Element} FileManagement component.
 */
const FileManagement = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchFiles();
  }, []);
  /**
   * Fetches the list of uploaded files.
   * @async
   */
  const fetchFiles = async () => {
    setIsLoader(true);
    try {
      const files = await listFiles();
      setUploadedFiles(files.data);
    } catch (error) {
      addNotification('error', error);
    } finally {
      setIsLoader(false);
    }
  };
  /**
   * Handles file upload.
   * @async
   */
  const handleUpload = async () => {
    setIsLoader(true);
    try {
      const response = await uploadFile(selectedFile);
      if (response?.error) {
        GenerateNotification(
          buildNotification({
            title: response.error.message,
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification({
            title: 'File Uploaded Successfully!',
            description: '',
            style: 'success',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        fetchFiles();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoader(false);
    }
  };
  /**
   * Handles file deletion.
   * @async
   * @param {string} fileId - ID of the file to delete.
   */
  const handleDelete = async (fileId) => {
    setIsLoader(true);
    try {
      const response = await deleteFile(fileId);
      if (response?.error) {
        GenerateNotification(
          buildNotification({
            title: response?.error.message,
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {

        GenerateNotification(
          buildNotification({
            title: 'File Deleted Successfully!',
            description: '',
            style: 'success',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        fetchFiles();
      }
    } catch (error) {
      addNotification('error', 'Error deleting file:', error);
    } finally {
      setIsLoader(false);
    }
  };
  /**
   * Dropzone Configuration.
   * @param {Object} e - Event object.
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf,.jsonl',
    onDrop: (acceptedFiles) => setSelectedFile(acceptedFiles[0]),
  });

  return (
    <div className='multi-image-upload k-pr-4 k-pl-4'>
      {isLoader && (
        <div className='loader-wrapper'>
          <Loader />
        </div>
      )}
      <h6 className='k-p-4'>Upload Files</h6>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {selectedFile && (
        <div className='k-p-4 k-text-right'>
          <Button
            className='ai-button-primary'
            onClick={handleUpload}
            disabled={isLoader || selectedFile.length === 0}
          >
            {isLoader ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
      )}
      <div className='k-p-4'>
        <Grid
          style={{ maxHeight: '400px', overflowY: 'auto' }}
          data={process(uploadedFiles, {})}
        >
          <GridColumn field='filename' title='Filename' />
          <GridColumn
            title='Actions'
            width='200px'
            cell={(props) => (
              <td>
                <Button
                  icon='trash'
                  look='flat'
                  onClick={() => handleDelete(props.dataItem.id)}
                />
              </td>
            )}
          />
          <GridToolbar />
        </Grid>
      </div>
    </div>
  );
};

export default FileManagement;
